import { datadogRum } from '@datadog/browser-rum'

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  env
} from '../utils/config'
import { TRACE_KEYS, getTraceId } from '../utils/trace'

export const initDatadog = ({
  /** Whether we should skip DD initialization */
  disabled = false
}) => {
  try {
    if (disabled) {
      console.debug('[Datadog] Skipped initialization')

      return
    }

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'journey-app-v3',
      env: env('REACT_APP_STAGE', 'unknown'),
      version: env('VERSION', 'unknown'),
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackViewsManually: true, // since we dont change the path, we need to manually track view changes, i.e. step changes
      useCrossSiteSessionCookie: true, // enables third party cookiestrackUserInteractions
      defaultPrivacyLevel: 'mask-user-input',
      // enrich event data with journeyId
      beforeSend: (event, _context) => {
        const url = new URL(event.view.url)

        event.context = {
          ...event.context,
          // Adds in the journey Id
          journeyId: url.searchParams.get('journeyId'),
          // Adds in the journey App's sessinon id
          journeySessionId: getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)
        }

        return true
      }
    })

    enrichContextWithBrowserAnalytics()

    console.debug('[Datadog] Initialized')
  } catch (error) {
    console.error('[Datadog] Failed to initialise', error)
    // fail gracefully
  }
}

export const enrichContextWithBrowserAnalytics = () => {
  try {
    let width = window.innerWidth || document.body.clientWidth
    let height = window.innerHeight || document.body.clientHeight

    width = Math.round(width / 100) * 100
    height = Math.round(height / 100) * 100

    const size = width + 'x' + height

    datadogRum.setGlobalContextProperty('browser-analytics', {
      browser_size: size
    })
  } catch (_error) {
    console.warn('[Datadog] Failed to enrich context with browser analytics')
    // fail gracefully
  }
}

/**
 * Return the DataDog Session Id.
 *
 * NOTE: If DataDog is not initialized, this will return `undefined`.
 */
export const getDataDogSessionId = () => {
  return datadogRum.getInternalContext()?.session_id
}
