export function setManifest(
  journeyName?: string,
  logoUrl?: string,
  themeColor?: string,
  backgroundColor?: string
) {
  const manifestJSON = {
    // 30 characters is the limits for android & ios
    short_name:
      !journeyName || journeyName.length > 30 ? 'epilot' : journeyName,
    name: journeyName || 'ePilot Journey',
    icons: logoUrl
      ? [
          {
            src: logoUrl
          }
        ]
      : [
          {
            src: 'favicon.ico',
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon'
          }
        ],
    start_url: window.location.href,
    display: 'standalone',
    theme_color: themeColor || '#000000',
    background_color: backgroundColor || '#ffffff',
    scope: window.location.origin
  }

  const stringManifest = JSON.stringify(manifestJSON)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)

  const htmlTag = document.querySelector('#my-manifest-placeholder')

  if (htmlTag) {
    htmlTag.setAttribute('href', manifestURL)
  }
}
