import '../assets/ExpandIcon.css';var _ = Object.defineProperty;
var n = Object.getOwnPropertySymbols;
var c = Object.prototype.hasOwnProperty, d = Object.prototype.propertyIsEnumerable;
var r = (o, s, e) => s in o ? _(o, s, { enumerable: !0, configurable: !0, writable: !0, value: e }) : o[s] = e, m = (o, s) => {
  for (var e in s || (s = {}))
    c.call(s, e) && r(o, e, s[e]);
  if (n)
    for (var e of n(s))
      d.call(s, e) && r(o, e, s[e]);
  return o;
};
var p = (o, s) => {
  var e = {};
  for (var a in o)
    c.call(o, a) && s.indexOf(a) < 0 && (e[a] = o[a]);
  if (o != null && n)
    for (var a of n(o))
      s.indexOf(a) < 0 && d.call(o, a) && (e[a] = o[a]);
  return e;
};
import { jsx as l } from "react/jsx-runtime";
import { c as f } from "./index-rKuIKazb.js";
import { I as i } from "./Icon-W0dPy6Pi.js";
const j = "_root_l5tju_1", u = "_expanded_l5tju_6", x = {
  root: j,
  expanded: u
}, g = (a) => {
  var t = a, {
    className: o,
    isExpanded: s
  } = t, e = p(t, [
    "className",
    "isExpanded"
  ]);
  return /* @__PURE__ */ l(
    i,
    m({
      className: f(
        x.root,
        s && x.expanded,
        o
      ),
      name: "expand_more"
    }, e)
  );
};
export {
  g as E
};
