import type {
  Design,
  EmbedOptions,
  Journey,
  StepState
} from '@epilot/journey-logic-commons'
import { getOptimizedFileIdURL } from '@epilot/journey-logic-commons'
import {
  HistoryStack,
  ValueError,
  useJourneyContext
} from '@epilot/json-renderers'

import useUpdateTheme from '../../hooks/useUpdateTheme'
import { STEPPER_DESKTOP_LIMIT, env } from '../../utils/config'
import { IsMobile, IsTablet } from '../../utils/tools'
import { DisplayJson } from '../DisplayJson'
import { JourneyStepper, JourneyStepperProps } from '../JourneyStepper'
import { StepComponent, StepComponentProps } from '../StepComponent'
import { TopBarMemoized } from '../TopBar'

export type LinearJourneyProps = {
  journey: Journey
  currentStepIndex: number
  debug?: boolean
  onNavigateToStep: JourneyStepperProps['onChangeStepIndex']
  onGoBack: () => void
  onCloseButtonClicked: () => void
  onChange: StepComponentProps['onChange']
  stepsState: StepState[]
  stepsErrors: ValueError[][]
  showTopBar?: boolean
  showCloseButton?: boolean
  isLinearJourney?: boolean
  submitJourneySuccess?: boolean
  remainingSteps?: number
  stack: HistoryStack[]
  mode: EmbedOptions['mode']
  isParentLauncherJourney?: boolean
}

export const LinearJourney = ({
  journey,
  currentStepIndex,
  debug,
  onNavigateToStep,
  onGoBack,
  onCloseButtonClicked,
  onChange,
  stepsState,
  showTopBar = true,
  isLinearJourney,
  remainingSteps,
  stack,
  submitJourneySuccess,
  mode,
  showCloseButton,
  stepsErrors,
  isParentLauncherJourney
}: LinearJourneyProps) => {
  const {
    context: { isLauncherJourney, errorValidationMode }
  } = useJourneyContext()

  const { logo: designBuiderLogoUrl } = useUpdateTheme()

  // Stepper type
  const mobile = IsMobile()
  const tablet = IsTablet()

  const currentStep = journey.steps[currentStepIndex]

  const isTopBarEnabled = mode === 'inline' ? showTopBar : true

  const isProgressBar =
    isLinearJourney === false ||
    (isLinearJourney && journey.steps.length > STEPPER_DESKTOP_LIMIT)

  const isTopBarBackButton = isProgressBar || (!mobile && !tablet)

  // use the logo that is passed from Design Builder if it exists
  // otherwise use the logo from the journey
  const logoUrl = designBuiderLogoUrl
    ? designBuiderLogoUrl
    : getLogoUrl(journey.design)

  const data = stepsState[currentStepIndex] ?? {}

  return (
    <>
      {!isLauncherJourney && (
        <TopBarMemoized
          isMobile={mobile || tablet}
          isProgressBar={isProgressBar}
          isTopBarEnabled={isTopBarEnabled}
          logoUrl={logoUrl}
          onBack={
            !submitJourneySuccess && isTopBarBackButton && currentStepIndex > 0
              ? onGoBack
              : undefined
          }
          onClose={
            mode === 'inline' &&
            !isParentLauncherJourney &&
            showCloseButton !== true
              ? undefined
              : onCloseButtonClicked
          }
        />
      )}

      {currentStep?.showStepper && !isLauncherJourney && (
        <JourneyStepper
          currentStepIndex={currentStepIndex}
          isMobile={mobile}
          isProgressBar={isProgressBar}
          isTablet={tablet}
          onChangeStepIndex={onNavigateToStep}
          remainingSteps={remainingSteps}
          stack={stack}
          steps={journey.steps}
        />
      )}

      {currentStep && (
        <StepComponent
          data={data}
          key={currentStep.stepId} // ref: https://e-pilot.atlassian.net/browse/STABLE360-3452
          onChange={onChange}
          step={{ ...currentStep, stepIndex: currentStepIndex }}
          validationMode={errorValidationMode}
        />
      )}

      {debug && (
        <>
          <DisplayJson data={stepsState[currentStepIndex]} />
          <DisplayJson data={stepsErrors[currentStepIndex]} />
        </>
      )}
    </>
  )
}

LinearJourney.displayName = 'LinearJourney'

const LOGO_HEIGHT = '30'

function getLogoUrl(design?: Design) {
  if (!design) {
    return ''
  }

  if (design.orgId && design.fileId) {
    return getOptimizedFileIdURL(
      env('REACT_APP_IMAGE_PREVIEW_API_URL'),
      design.orgId,
      design.fileId,
      undefined,
      LOGO_HEIGHT
    )
  }

  return design.logoUrl
}
