import '../assets/StepperInput.css';var $ = Object.defineProperty, q = Object.defineProperties;
var D = Object.getOwnPropertyDescriptors;
var u = Object.getOwnPropertySymbols;
var k = Object.prototype.hasOwnProperty, x = Object.prototype.propertyIsEnumerable;
var g = (e, o, t) => o in e ? $(e, o, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[o] = t, l = (e, o) => {
  for (var t in o || (o = {}))
    k.call(o, t) && g(e, t, o[t]);
  if (u)
    for (var t of u(o))
      x.call(o, t) && g(e, t, o[t]);
  return e;
}, d = (e, o) => q(e, D(o));
var f = (e, o) => {
  var t = {};
  for (var r in e)
    k.call(e, r) && o.indexOf(r) < 0 && (t[r] = e[r]);
  if (e != null && u)
    for (var r of u(e))
      o.indexOf(r) < 0 && x.call(e, r) && (t[r] = e[r]);
  return t;
};
import { jsxs as F, jsx as s } from "react/jsx-runtime";
import { c as a } from "./index-rKuIKazb.js";
import { forwardRef as L } from "react";
import { T as z } from "./TextField-DPuf80eb.js";
import { I as R } from "./Icon-W0dPy6Pi.js";
import { B as G } from "./Button-BslQU2T7.js";
const H = "_root_1l6iw_1", J = "_label_1l6iw_22", K = "_adornment_1l6iw_74", M = "_error_1l6iw_108", n = {
  root: H,
  "root--fullWidth": "_root--fullWidth_1l6iw_17",
  label: J,
  "input-field-root": "_input-field-root_1l6iw_33",
  "input-field-root--fullWidth": "_input-field-root--fullWidth_1l6iw_66",
  adornment: K,
  "adornment-left": "_adornment-left_1l6iw_97",
  "adornment-right": "_adornment-right_1l6iw_102",
  error: M
}, O = L(
  (e, o) => {
    const y = e, {
      startAdornment: t,
      endAdornment: r,
      onStartIconClick: m,
      onEndIconClick: j,
      label: h,
      isDisabled: I,
      isError: b,
      isRequired: N,
      errorColor: C,
      borderColor: S,
      id: c,
      style: w,
      containerProps: p,
      inputContainerProps: _,
      adornmentProps: i,
      iconProps: A,
      isFullWidth: W
    } = y, B = f(y, [
      "startAdornment",
      "endAdornment",
      "onStartIconClick",
      "onEndIconClick",
      "label",
      "isDisabled",
      "isError",
      "isRequired",
      "errorColor",
      "borderColor",
      "id",
      "style",
      "containerProps",
      "inputContainerProps",
      "adornmentProps",
      "iconProps",
      "isFullWidth"
    ]), E = {
      "--stepper-field-error-color": C,
      "--stepper-field-border-color": S
    }, T = l(l({}, w), E);
    return /* @__PURE__ */ F(
      "div",
      d(l({}, p), {
        className: a(
          n.root,
          W && n["root--fullWidth"],
          p == null ? void 0 : p.className
        ),
        style: T,
        children: [
          h && /* @__PURE__ */ F(
            "label",
            {
              className: a("Concorde-StepperInput", n.label),
              htmlFor: c,
              children: [
                h,
                N && /* @__PURE__ */ s(
                  "span",
                  {
                    "aria-hidden": "true",
                    className: a(
                      b && !I && n.error
                    ),
                    children: " *"
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ s(
            z,
            d(l({}, B), {
              borderColor: S,
              containerProps: d(l({}, _), {
                className: a(
                  "Concorde-StepperInput__InputField-Root",
                  n["input-field-root"],
                  W && n["input-field-root--fullWidth"],
                  _ == null ? void 0 : _.className
                )
              }),
              endAdornment: /* @__PURE__ */ s(
                v,
                d(l({
                  onClick: j
                }, i), {
                  className: a(
                    "Concorde-StepperInput__Adornment-Right",
                    n["adornment-right"],
                    i == null ? void 0 : i.className
                  ),
                  id: c && `${c}_add_button`,
                  children: r || /* @__PURE__ */ s(R, d(l({}, A), { name: "add" }))
                })
              ),
              errorColor: C,
              id: c,
              isDisabled: I,
              isError: b,
              isRequired: N,
              ref: o,
              startAdornment: /* @__PURE__ */ s(
                v,
                d(l({
                  onClick: m
                }, i), {
                  className: a(
                    "Concorde-StepperInput__Adornment-Left",
                    n["adornment-left"],
                    i == null ? void 0 : i.className
                  ),
                  id: c && `${c}_remove_button`,
                  children: t || /* @__PURE__ */ s(R, d(l({}, A), { name: "remove" }))
                })
              ),
              style: w,
              type: "tel"
            })
          )
        ]
      })
    );
  }
), v = (r) => {
  var m = r, {
    children: e,
    className: o
  } = m, t = f(m, [
    "children",
    "className"
  ]);
  return /* @__PURE__ */ s(
    G,
    l({
      className: a(
        "Concorde-StepperInput__Adornment",
        n.adornment,
        o
      ),
      color: "auto",
      label: e
    }, t)
  );
};
O.displayName = "StepperInput";
export {
  O as S
};
