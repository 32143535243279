import {
  blockController,
  CONTROL_NAME,
  BLOCK_LOCATOR_SEPARATOR
} from '@epilot/journey-logic-commons'
import type {
  Step,
  BlockLocatorDetails,
  StepState,
  ContactControlOptions,
  AddressControlOptions,
  DisplayConditionsStatus
} from '@epilot/journey-logic-commons'
import {
  mapToContactOptions,
  formatDeviceConsumptionStr,
  isInputCalculatorControlData
} from '@epilot/json-renderers'
import isEmpty from 'lodash/isEmpty'
import type { TFunction } from 'react-i18next'

import { getFilesData } from '../services/journey-service'
import { POSSIBLE_JOURNEY_ENTITIES } from '../utils/generateJourneyDataSources'
import type { DataSources } from '../utils/types'

export function getFileEntities(
  dataSources: DataSources,
  journeyState: StepState[],
  filePurposes?: string[],
  fileUploadFix?: boolean
) {
  const sourceFiles = dataSources[POSSIBLE_JOURNEY_ENTITIES.FILES]

  return sourceFiles
    ? getFilesData(sourceFiles, journeyState, filePurposes, fileUploadFix)
    : []
}

export const isFaultyEmail = (
  steps: Step[],
  userData: Record<string, unknown>[],
  historyIndexes: number[]
) => {
  const blocksContact = blockController
    .findBlocks(steps, { type: CONTROL_NAME.PERSONAL_INFORMATION_CONTROL })
    .filter((block) => {
      // filter blocks by only the ones having the email field required
      if (block?.uischema?.options) {
        const options = mapToContactOptions(block.uischema.options)

        if (!('error' in options)) {
          if (options.fields?.email?.required) {
            return true
          }
        } else {
          console.error('Error when mapping to contact options', {
            error: options.error
          })
        }
      }

      return false
    })
    .filter((block) => historyIndexes.includes(block.stepIndex)) // only include blocks which we actually seen by the user (i.e. were not skipped through logic)

  // check if required email field in contact blocks found
  if (Array.isArray(blocksContact) && blocksContact.length > 0) {
    const isValid = blocksContact.every((block) => {
      // check if required email field in contact blocks is under a group
      if (block.uischema?.options?.isNested) {
        // Group will always 1 for each block as long its nested
        const parentGroup = blockController
          .findBlocks([steps[block.stepIndex]], {
            type: CONTROL_NAME.GROUP_LAYOUT
          })
          .find((group) => group.scope === block?.parent?.scope)

        if (
          parentGroup &&
          parentGroup?.uischema?.options?.['display'] === false
        ) {
          return true
        }
      }

      if (block.uischema?.options?.['display'] === false) {
        return true // is valid if required email field, but block is hidden
      }

      const values = userData[block.stepIndex][block.name]

      // check that property email exists in values and is not empty
      if (
        typeof values === 'object' &&
        values !== null &&
        'email' in values &&
        values['email']
      ) {
        return true
      }

      return false
    })

    return !isValid
  }

  // has no error if no related blocks found
  return false
}

/**
 * This function is used to sync autofill address blocks
 * @param steps
 * @param displayConditionsStatus
 * @param userData
 */
export const autoFillAddressesData = (
  steps: Step[],
  displayConditionsStatus: DisplayConditionsStatus,
  userData: StepState[]
) => {
  const newUserData = { ...userData }

  // get all address blocks
  const allAddressesBlocks =
    blockController.findBlocks(steps, {
      type: CONTROL_NAME.ADDRESS_BLOCK
    }) || []

  // loop through all address blocks to apply the logic for the valid identical address blocks
  allAddressesBlocks.forEach((block) => {
    if (block?.uischema?.options) {
      const options = block.uischema.options as AddressControlOptions

      // get valid address blocks with identical address feature
      if (
        shouldAutoFillAddress(
          block,
          options,
          newUserData[block.stepIndex][block.name],
          displayConditionsStatus
        )
      ) {
        const relatedAddressParts = options?.autoFillAddressSettings?.relatedBlock?.split(
          BLOCK_LOCATOR_SEPARATOR
        )

        const relatedAddressStepId = relatedAddressParts?.[0]
        const relatedAddressBlockName = relatedAddressParts?.[1]

        if (relatedAddressStepId && relatedAddressBlockName) {
          const relatedAddressStepIndex = steps.findIndex(
            (step) => step.stepId === relatedAddressStepId
          )

          // get the value of the related address block
          const relatedAddressData =
            relatedAddressStepIndex > -1 &&
            userData[relatedAddressStepIndex]?.[relatedAddressBlockName]

          // if the related address block has a value, then set the value of the current address block to the value of the related address block
          if (typeof relatedAddressData === 'object') {
            newUserData[block.stepIndex][block.name] = {
              ...relatedAddressData
            }
          }
        }
      }
    }
  })

  return newUserData
}

/**
 * This function is used to sync autofill personal information blocks
 * @param steps
 * @param displayConditionsStatus
 * @param userData
 */
export const autoFillPIData = (
  steps: Step[],
  displayConditionsStatus: DisplayConditionsStatus,
  userData: StepState[]
) => {
  const newUserData = { ...userData }

  // get all pi blocks
  const allPIBlocks =
    blockController.findBlocks(steps, {
      type: CONTROL_NAME.PERSONAL_INFORMATION_CONTROL
    }) || []

  // loop through all pi blocks to apply the logic for the valid identical pi blocks
  allPIBlocks.forEach((block) => {
    if (block?.uischema?.options) {
      const options = block.uischema.options as ContactControlOptions

      // get valid pi blocks with identical pi feature
      if (
        shouldAutoFillPI(
          block,
          options,
          newUserData[block.stepIndex][block.name],
          displayConditionsStatus
        )
      ) {
        const relatedPIParts = options?.autoFillPISettings?.relatedBlock?.split(
          BLOCK_LOCATOR_SEPARATOR
        )

        const relatedPIStepId = relatedPIParts?.[0]
        const relatedPIBlockName = relatedPIParts?.[1]

        if (relatedPIStepId && relatedPIBlockName) {
          const relatedPIStepIndex = steps.findIndex(
            (step) => step.stepId === relatedPIStepId
          )

          // get the value of the related pi block
          const relatedPIData =
            relatedPIStepIndex > -1 &&
            userData[relatedPIStepIndex]?.[relatedPIBlockName]

          // if the related pi block has a value, then set the value of the current pi block to the value of the related pi block
          if (typeof relatedPIData === 'object') {
            newUserData[block.stepIndex][block.name] = {
              ...relatedPIData
            }
          }
        }
      }
    }
  })

  return newUserData
}

/**
 * Check if the current address block should be synced with the related address block
 * @param options
 * @returns
 */
const shouldAutoFillAddress = (
  block: BlockLocatorDetails,
  options: AddressControlOptions & { display?: boolean },
  blockData: unknown,
  displayConditionsStatus: DisplayConditionsStatus
) => {
  // cover the case when the block is inside a block
  const blockName = block.parent?.scope?.split('/')?.pop() || block.name

  const blockDisplayCondStatus =
    displayConditionsStatus[`${block.stepIndex}/${blockName}`]

  if (
    options.isAutoFillAddressEnabled &&
    options.autoFillAddressSettings?.relatedBlock &&
    isEmpty(blockData) &&
    blockDisplayCondStatus?.display === false
  ) {
    const triggerKey = blockDisplayCondStatus?.trigger

    // make sure that the trigger block is visible (edge case when we have a previous navigation logic or nested blocks)
    if (triggerKey) {
      const triggerDisplayCondition = displayConditionsStatus[triggerKey]

      return (
        triggerDisplayCondition === undefined ||
        triggerDisplayCondition?.display !== false
      )
    }

    return false
  }
}

/**
 * Check if the current pi block should be synced with the related pi block
 * @param options
 * @returns
 */
const shouldAutoFillPI = (
  block: BlockLocatorDetails,
  options: ContactControlOptions & { display?: boolean },
  blockData: unknown,
  displayConditionsStatus: DisplayConditionsStatus
) => {
  // cover the case when the block is inside a block
  const blockName = block.parent?.scope?.split('/')?.pop() || block.name

  const blockDisplayCondStatus =
    displayConditionsStatus[`${block.stepIndex}/${blockName}`]

  if (
    options.isAutoFillPIEnabled &&
    options.autoFillPISettings?.relatedBlock &&
    isEmpty(blockData) &&
    blockDisplayCondStatus?.display === false
  ) {
    const triggerKey = blockDisplayCondStatus?.trigger

    // make sure that the trigger block is visible (edge case when we have a previous navigation logic or nested blocks)
    if (triggerKey) {
      const triggerDisplayCondition = displayConditionsStatus[triggerKey]

      return (
        triggerDisplayCondition === undefined ||
        triggerDisplayCondition?.display !== false
      )
    }

    return false
  }
}

/**
 * This function compact the input calculator data.
 * @param steps
 * @param userData
 */
export const compactInputCalculatorData = (
  steps: Step[],
  userData: StepState[],
  t: TFunction
) => {
  const newUserData = { ...userData }

  const allInputCalculatorBlocks =
    blockController.findBlocks(steps, {
      type: CONTROL_NAME.INPUT_CALCULATOR_CONTROL
    }) || []

  allInputCalculatorBlocks.forEach((block) => {
    const blockIndex = block.stepIndex
    const blockName = block.name
    const blockData = newUserData[blockIndex]?.[blockName]

    if (
      isInputCalculatorControlData(blockData) &&
      blockData !== null &&
      blockData !== undefined &&
      blockData.devices?.length
    ) {
      const unitLabel = blockData.numberUnit
        ? t(`units.${blockData.numberUnit}`, blockData.numberUnit)
        : ''

      // todo: create a separate field to save the string to avoid overriding the original data
      // this transformed data is used for the mapping purposes
      blockData.devices = blockData.devices.map((device) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return formatDeviceConsumptionStr(device, unitLabel) as any // here it could be string[] or InputCalculatorDevice[]
      })
    }
  })

  return newUserData
}
