const JOURNEY_LOCAL_BASE_URL = 'http://localhost:9000'

import { env as envConfig } from '../env'

// TODO: Improve types to infer return type based on the env name
export const env = <R = string>(name: keyof typeof envConfig, fallback?: R) => {
  return ((envConfig[name] ?? fallback) as unknown) as R
}

export const VERSION = env('VERSION', 'local')

/** @deprecated seems not to be used, should be removed if confirmed */
export const RULE_SOURCE_SEPARATOR = '/'

/** When will the desktop stepper switch into mobile one */
export const STEPPER_DESKTOP_LIMIT = 8

/** Datadog id and client token */
export const DATADOG_APPLICATION_ID = 'df1ff5a4-412d-4da3-b4d6-99b2f797fc41'
export const DATADOG_CLIENT_TOKEN = 'pubcbfbb4b2a92ed722ad8c1e40b2880553'

export const isLocalOriginEventAllowed = (origin: string) => {
  return (
    origin === JOURNEY_LOCAL_BASE_URL &&
    ['dev', 'local'].includes(env('REACT_APP_STAGE'))
  )
}

export const TIME_TO_FIRST_RENDER_METRIC = 'timeToFirstRender'
