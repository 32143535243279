import '../assets/Card.css';var y = Object.defineProperty;
var a = Object.getOwnPropertySymbols;
var n = Object.prototype.hasOwnProperty, u = Object.prototype.propertyIsEnumerable;
var l = (o, e, r) => e in o ? y(o, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : o[e] = r, t = (o, e) => {
  for (var r in e || (e = {}))
    n.call(e, r) && l(o, r, e[r]);
  if (a)
    for (var r of a(e))
      u.call(e, r) && l(o, r, e[r]);
  return o;
};
var f = (o, e) => {
  var r = {};
  for (var c in o)
    n.call(o, c) && e.indexOf(c) < 0 && (r[c] = o[c]);
  if (o != null && a)
    for (var c of a(o))
      e.indexOf(c) < 0 && u.call(o, c) && (r[c] = o[c]);
  return r;
};
import { jsx as k } from "react/jsx-runtime";
import { c as v } from "./index-rKuIKazb.js";
import { forwardRef as w } from "react";
const N = "_root_1wejj_1", h = "_featured_1wejj_24", m = {
  root: N,
  featured: h
}, B = w(
  (o, e) => {
    const s = o, {
      backgroundColor: r,
      className: c,
      isFeatured: C,
      featuredColor: i,
      featuredLabelColor: _,
      featuredText: d,
      hoverBgColor: b,
      style: j
    } = s, p = f(s, [
      "backgroundColor",
      "className",
      "isFeatured",
      "featuredColor",
      "featuredLabelColor",
      "featuredText",
      "hoverBgColor",
      "style"
    ]), g = {
      "--concorde-card-featured-text": d ? `"${d}"` : "",
      "--concorde-card-featured-color": i,
      "--concorde-card-featured-label-color": _,
      "--concorde-card-background-color": r,
      "--concorde-card-hover-background-color": b
    }, x = t(t({}, j), g);
    return /* @__PURE__ */ k(
      "div",
      t({
        className: v(
          "Concorde-Card",
          m.root,
          C && m.featured,
          c
        ),
        ref: e,
        style: x
      }, p)
    );
  }
);
B.displayName = "Card";
export {
  B as C
};
