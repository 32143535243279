import {
  BLOCK_LOCATOR_SEPARATOR,
  DEFAULT_FILTER_VALUES_CONFIG_NAME,
  isBlockUiSchema,
  isUiSchemaWithElements
} from '@epilot/journey-logic-commons'
import type { Step, UiSchema } from '@epilot/journey-logic-commons'

/**
 * Injects a filter into a step config.
 * @param targetStep
 * @param targetBlockName
 * @param filterKey
 * @param filterValue
 * @param configPropertyName
 */
export function injectFilterInStepConfig(
  targetStep: Step,
  targetBlockName: string,
  filterKey: string,
  filterValue: unknown,
  configPropertyName = DEFAULT_FILTER_VALUES_CONFIG_NAME
) {
  const { uischema } = targetStep
  const elements = isUiSchemaWithElements(uischema) ? uischema?.elements : []

  if (elements) {
    injectFilterInUiSchema(
      elements,
      filterKey,
      filterValue,
      targetBlockName,
      configPropertyName
    )
  }
}

/**
 * Injects a filter into a ui schema.
 * @param uiSchemaElements
 * @param filterKey
 * @param filterValue
 * @param targetBlockName
 * @param configPropertyName
 */
function injectFilterInUiSchema(
  uiSchemaElements: UiSchema[] | UiSchema[][],
  filterKey: string,
  filterValue: unknown,
  targetBlockName: string,
  configPropertyName: string
) {
  for (const element of uiSchemaElements) {
    if (Array.isArray(element) && element.length > 0) {
      injectFilterInUiSchema(
        element,
        filterKey,
        filterValue,
        targetBlockName,
        configPropertyName
      )
    } else if (isUiSchemaWithElements(element)) {
      injectFilterInUiSchema(
        element.elements,
        filterKey,
        filterValue,
        targetBlockName,
        configPropertyName
      )
    } else if (isBlockUiSchema(element)) {
      const blockName = element.scope.split(BLOCK_LOCATOR_SEPARATOR).pop()

      if (blockName === targetBlockName) {
        const filterValues = element.options?.[configPropertyName]

        if (filterValues) {
          filterValues[filterKey] = filterValue
        } else {
          element.options = {
            ...element.options,
            [configPropertyName]: { [filterKey]: filterValue }
          }
        }
        break
      }
    }
  }
}
