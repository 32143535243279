import {
  BLOCK_LOCATOR_SEPARATOR,
  isConditionTrue,
  LogicTriggerEventName
} from '@epilot/journey-logic-commons'
import type {
  InjectFilterLogic,
  Journey,
  NavigateFromStepLogic,
  StepState
} from '@epilot/journey-logic-commons'

import { injectFilterInStepConfig } from '../../utils/injectFilterInStepConfig'

export const applyFilterInjectionLogics = async (
  journey: Journey,
  filterInjectionLogics: Array<Array<InjectFilterLogic>>,
  currentStepIndex: number,
  trueNavigationLogic: NavigateFromStepLogic | undefined,
  triggerEvent: LogicTriggerEventName,
  triggerBlockName: string | undefined,
  currentStepState: StepState
): Promise<number> => {
  const filterInjectLogicsToApply =
    filterInjectionLogics[currentStepIndex]?.filter((logic) => {
      const firstConditionEvent = logic.conditionsRelatedToStep?.[0].event
      const isMatchingEvent = firstConditionEvent === triggerEvent
      const isMatchingBlock = logic.blockName === triggerBlockName

      if (triggerEvent === LogicTriggerEventName.VALUE_CHANGE) {
        if (trueNavigationLogic) {
          return (
            firstConditionEvent === LogicTriggerEventName.NEXT ||
            (isMatchingEvent && isMatchingBlock)
          )
        }

        return isMatchingEvent && isMatchingBlock
      }

      return isMatchingEvent
    }) ?? []

  for (const {
    actionsIncludesFilter: actions,
    conditionsRelatedToStep: conditions,
    blockName,
    stepId: logicStepId,
    injectedFilterValueName
  } of filterInjectLogicsToApply) {
    const stepId =
      currentStepState.stepId || currentStepState.name || logicStepId
    const targetStep = journey.steps.find(
      (step) => step.stepId === actions[0].targetStepId
    )

    if (
      blockName &&
      conditions?.length > 0 &&
      actions?.length > 0 &&
      targetStep
    ) {
      const result = await isConditionTrue(
        conditions[0],
        currentStepState[blockName]
      )
      const filterValue =
        result === actions[0].conditionResult ? currentStepState[blockName] : ''

      injectFilterInStepConfig(
        targetStep,
        actions[0].targetBlockName,
        stepId + BLOCK_LOCATOR_SEPARATOR + blockName,
        filterValue,
        injectedFilterValueName
      )
    }
  }

  return filterInjectLogicsToApply.length
}
