import { nanoid } from 'nanoid'

const cachedMap = new Map<string, string>()

export enum TRACE_KEYS {
  /**
   * Used to identify a given Journey App session and its correlation with other resources
   * such as DataDog RUM sessions, actually uploaded files, submitted fallback file, etc.
   *
   * This is useful when we need to trace errors or recover data (e.g. uploaded files) from failed submissions.
   */
  JOURNEY_SESSION_ID = 'journey_session_id'
}

/**
 * Generates a new trace ID for the given key or returns it from the cache.
 *
 * This is useful to keep immutable trace IDs for things like session IDs that help
 * associated resources to a user session.
 *
 * WARNING: To help determine how long your ID should be in terms of collision probability,
 * please go to {@link https://zelark.github.io/nano-id-cc/}
 */
export const generateTraceId = (
  traceKey: TRACE_KEYS,
  length = 16,
  regenerate = false
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!regenerate && cachedMap.has(traceKey)) return getTraceId(traceKey)!

    const traceId = nanoid(length)

    cachedMap.set(traceKey, traceId)

    return traceId
  } catch (error) {
    // fail gracefully
    console.error(`Failed to generate "${traceKey}" trace Id.`, error)
  }
}

/**
 * Returns a trace ID for the given key.
 *
 * WARNING: It isn't recommended that you cache this value but rather call this function
 * at the very moment you need it.
 */
export const getTraceId = (traceKey: TRACE_KEYS) => {
  return cachedMap.get(traceKey)
}
